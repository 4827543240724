<template>
    <div class="container" style="display:flex;">
        <div style="width:70%;">
            <el-form  ref="form" :model="form" label-width="100px" style="margin-top: 20px" label-position="left">
                <el-form-item label="媒体类型" >
                    <el-select v-model="form.typeArray" clearable  placeholder="请选择媒体类型">
                        <el-option
                            v-for="item in mediaTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="车站">
                    <el-cascader
                    style="width: 660px"
                    :show-all-levels="false"
                    placeholder="请选择车站"
                    :options="ddlStation"
                    :props="{multiple: true}"
                    v-model="form.stationArray"
                    clearable
                    ></el-cascader>
                </el-form-item>
                <el-row>
                <el-form-item label="关键字"  >
                    <el-input  v-model="form.key" placeholder="请输入媒体编号关键字"></el-input>
                </el-form-item>
                <el-button style="margin-left: 60px; height: 20px" type="primary" @click="onMediaQuery">检索</el-button>
                </el-row>
            </el-form>
            <div style="display:flex;justify-content:space-between;">
                资源库
                <!-- <el-button @click="resetMediaTypeFilter">清除类型过滤器</el-button> -->
                <!-- <el-button style="margin-right:20px;" @click="clearFilter">清除所有过滤器</el-button> -->
            </div>
                <el-table ref="filterTable" :data="mediaList"
                    style="width: 100%" @selection-change="handleMediaSelectionChange"
                    :row-key="getRowKey" stripe >
                    <el-table-column type="selection" reserve-selection width="55"></el-table-column>
                    <el-table-column prop="typeName" label="类型" sortable width="90" ></el-table-column>
                    <el-table-column prop="ydarea" label="大区" sortable width="120"></el-table-column>
                    <el-table-column prop="city" label="城市" sortable width="90" ></el-table-column>
                    <el-table-column prop="station" label="车站" sortable width="150"></el-table-column>
                    <el-table-column prop="mediaCode" label="媒体编号" width="160" sortable >
                        <template v-slot="scope">  
                            <div  @click="openSet(scope.row)">                          
                                <div v-if="scope.row.tagCnt>0" style="color: #409EFF;cursor: pointer;">{{scope.row.mediaCode}}({{scope.row.tagCnt}})</div>
                                <div v-else  style="cursor: pointer;">{{scope.row.mediaCode}}</div>
                            </div>
                        </template>
                        <!-- <template  v-slot:header>
                            <el-input v-model="search" size="mini" placeholder="输入资源编号关键字"/>
                            <span>媒体编号</span>
                        </template>   -->
                    </el-table-column>
                    <el-table-column prop="address" label="具体位置" :formatter="formatter" ></el-table-column>
                </el-table>
            
        </div>
        <div style="width:30%;margin-left:15px;">
            标签库<span v-if="selectTagCnt>0" >(已选{{selectTagCnt}}项)</span><el-button style="margin-left:20px;" type="text" @click="clearSelTag();">清除选中的标签</el-button>
            <!-- <el-table  ref="toSelTagTable"
                :data="rightLabelList.filter(data => !search || data.label.toLowerCase().includes(search.toLowerCase()))"
                style="width: 100%;margin-top:20px;" @selection-change="handleTagSelectionChange"
                :row-key="getTagRowKey" stripe>
                <el-table-column type="selection" reserve-selection width="55"></el-table-column>
                <el-table-column prop="label" label="标签" width="280">
                    <template v-slot="scope">  
                        <div>                            
                            <div style="cursor: pointer;">{{scope.row.label}}</div>
                        </div>
                    </template>
                    <template  v-slot:header>
                        <el-input v-model="search" size="mini" style="width:180px;" placeholder="输入标签关键字"/>
                        <el-button type="text" @click="clearSelTag();">清除选中</el-button>
                        <span></span>
                    </template>  
                </el-table-column>
            </el-table> -->
            <el-tree
                :data="GetMediaTagList"
                show-checkbox
                node-key="Id"    
                ref="tree"
                highlight-current    
                @check="tagChecked"       
                :props="defaultProps">
            </el-tree>

        </div>

        <el-button class="fixbutton" type="primary" round @click="onConnect(0)">全新关联</el-button>
        <el-button class="fixbutton2" type="primary" round @click="onConnect(1)">追加关联</el-button>
        <el-dialog title="设置资源标签" v-model="editDialogVisible" width="80%">
            <el-form :model="editForm"  label-width="150px" label-position="left">
                <el-form-item label="车站">
                    <div>{{curItem.station}}</div>
                </el-form-item>   
                <el-form-item label="位置">
                    <div>{{curItem.address}}</div>
                </el-form-item>  
                <el-form-item label="媒体">
                    <div>{{curItem.mediaCode}}</div>
                </el-form-item>              

                <el-row>
                  <p style="text-align: left; margin: 0 0 20px 45px;font-size: 14px;color: #606266;font-weight: 700;">标签</p>
                    <div  class="edit_dev" >
                      <el-transfer
                        style="text-align: left; display: inline-block;"
                        v-model="editForm.selTagIdArray"
                        filterable                        
                        :render-content="renderFunc"
                        :titles="['备选标签', '已选标签']"
                        :button-texts="['移除', '选中']"
                        :format="{
                          noChecked: '${total}',
                          hasChecked: '${checked}/${total}'
                        }"                        
                        :data="labelList">
                      </el-transfer>
                    </div>                    
                </el-row>
            </el-form>
            <div style="display:flex;justify-content:center;">
                <el-button @click="editDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="onSingleConnect">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import {Label_SetMediaTag} from "@/api/tag";
import {getToken} from "@/utils/auth";

var that=this;

    export default {
        name: "cart",
        components:{},
        data(){
            return {
                mediaList:[],
                rightLabelList:[],

                selectedMediaList:[],
                selectedTagList:[],

                editDialogVisible:false,
                curItem:{},
                editForm:{
                    selTagIdArray:[]
                },


                ddlStation:[],
                form: {
                    stationArray: [],
                    // ptArray: [],
                    // selectedList:[],
                    typeArray:[],
                    key:'',
                },
                search:'',

                defaultProps: {
                    children: 'SubTag',
                    label: 'Name',
                    
                },
                selectTagCnt:0,
            }
        },
        computed: {...mapGetters(['GetMediaTagList','Label_MediaList','labelList','Label_GetMediaTag', "mediaTypeList","areaStationList"])},

        async mounted(){
            await this.$store.dispatch("operate/areaStationList");
            await this.$store.dispatch("operate/mediaTypeList");
            await this.$store.dispatch('operate/GetMediaTagList');
            
            this.ddlStation = this.areaStationList;
            await this.$store.dispatch('tag/labelList').then(()=>{
                this.rightLabelList=this.labelList;
            })
        },

        methods: {
            async onMediaQuery(){
                const {stationArray,typeArray,key} =  this.form;        
                // if (stationArray.length==0){
                //     this.$message.success('请选择车站');
                //     return;
                // }
                var stationIds='';
                stationArray.forEach(ele => {
                    if (stationIds!=''){
                        stationIds=stationIds+','+ele[2]+'';
                    }else{
                        stationIds=ele[2]+'';
                    }
                });
                var mtypeIds='';
                // console.log('typeArray',typeArray)
                // if (typeArray.length>0){
                //     mtypeIds = typeArray.join(',');
                // }
                if (typeArray){
                    mtypeIds = typeArray+'';
                }
                await this.$store.dispatch('tag/Label_MediaList',{mtypeIds,stationIds,key}).then(()=>{
                    this.mediaList=this.Label_MediaList[0];  
                });
            },

            clearSelTag(){
                // this.$refs.toSelTagTable.clearSelection();
                // this.selectedTagList=[];
                this.$refs.tree.setCheckedKeys([]);
                this.selectTagCnt = 0;
            },
            tagChecked(){
                var list = this.$refs.tree.getCheckedNodes();
                var i=0;
                list.forEach(ele => {
                    if (ele.SubTag.length==0){
                        i=i+1;
                    }
                });
                this.selectTagCnt = i;
            },  

            async onConnect(flag){
                // console.log('aaa',this.selectedTagList);
                // return;
                if (!this.selectedMediaList || this.selectedMediaList.length==0){
                    this.$message.success('请选择资源');
                    return;
                }

                var medias =[];
                this.selectedMediaList.forEach(element => {
                    medias.push(element.mediaId);
                });

                // console.log('tag',this.$refs.tree.getCheckedKeys());
                // return;
                var tags =this.$refs.tree.getCheckedKeys();
                if (flag==0){
                    if (tags.length==0){
                        this.$confirm('是否确认删除选中资源的关联关系吗？ 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(async () => {    
                            this.saveConnect(medias,tags,'删除成功',1,);                              
                        })
                    }else{
                        this.saveConnect(medias,tags,'关联成功',1);                      
                    }
                }else{
                    this.saveConnect(medias,tags,'关联成功',1,1); 
                }
                
                
            },

            onSingleConnect(){
                var medias =[this.curItem.mediaId];
                
                if (this.editForm.selTagIdArray.length<1){
                    this.$confirm('是否确认删除当前资源的关联关系吗？ 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {                        
						this.saveConnect(medias,this.editForm.selTagIdArray,'删除成功',2);  
					})
                }else{
                    this.saveConnect(medias,this.editForm.selTagIdArray,'保存成功',2);  
                }
                this.editDialogVisible=false;
                
            },

            async saveConnect(medias,tags,msg,flag,append=0){                     
				await Label_SetMediaTag({
					mediaIds:medias.join(','),
					tagIds:tags.join(','),
                    append									
				}).then(()=>{
                    this.$message.success(msg);                            
                    this.setMediaTagCnt(medias,flag);                  
                })
				
            },

            //flag :1 列表页关联；2：详细页关联
            setMediaTagCnt(selMediaList,flag){
                // 
                var tagCnt = 0;
                // if (selMediaList[0].mediaId){
                if (flag===1){
                    var list = this.$refs.tree.getCheckedKeys();
                    tagCnt = list.length;
                }else{
                    tagCnt = this.editForm.selTagIdArray.length;
                }
                console.log('aaaa',flag,tagCnt,selMediaList)
                selMediaList.forEach(selE => {   
                    // var index=0;                 
                    this.mediaList.forEach(eleM => {
                        if (selE.mediaId){
                            if (eleM.mediaId===selE.mediaId){
                                eleM.tagCnt = tagCnt;   
                                // console.log('bbb1',tagCnt)
                                // that.$set(this.mediaList,index,eleM);                         
                            }
                        }else{
                            if (eleM.mediaId===selE){
                                eleM.tagCnt = tagCnt;   
                                // console.log('bbb2',tagCnt)
                                // that.$set(this.mediaList,index,eleM);                         
                            }
                        }
                        // index++;
                    });

                    // let selectedM = {};
                    // selectedM = this.mediaList.find((item)=>{
                    //     return item.mediaId === selE.mediaId;
                    //     //筛选出匹配数据，是对应数据的整个对象
                    // });
                    // selectedM.tagCnt = tagCnt;
                });
                this.$refs.filterTable.$forceUpdate();
                this.clearSelTag();
            },

            async openSet(item){
                this.curItem=item;
                // console.log('aaa');
                await this.$store.dispatch('tag/Label_GetMediaTag',{mediaId:item.mediaId}).then(()=>{
                    var subArr=[];
                    this.Label_GetMediaTag.forEach(element => {
                        if (element){        
                            subArr.push(parseInt(element.labelId));
                        }
                    });
                    this.editForm.selTagIdArray=subArr;
                    this.editDialogVisible=true;
                    // console.log('bbb');
                })
                
            },

            // resetMediaTypeFilter() {
            //     this.$refs.filterTable.clearFilter('type');
            // },
            // clearFilter() {
            //     this.$refs.filterTable.clearFilter();
            // },
            formatter(row, column) {
                return row.address;
            },
            // filterTag(value, row) {
            //     return row.tag === value;
            // },


            handleMediaSelectionChange(val) {				
                this.selectedMediaList = val;				
            },

            handleTagSelectionChange(val) {				
                this.selectedTagList = val;				
            },

            getRowKey(row) {
                return row.mediaId;
            },

            getTagRowKey(row) {
                return row.key;
            },

          
        }
    }
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }

   .edit_dev >>> .el-transfer-panel {
     width:350px;
   }

   .fixbutton {
   /*  设置图片相对于浏览器窗口固定*/
        position: fixed;
    /* 设置图片相对于浏览器的位置
    距离右边180px,底部20px,宽度50px
*/
        right :80px;
        bottom:20px;
        width:80px;
    }

    .fixbutton2 {
   /*  设置图片相对于浏览器窗口固定*/
        position: fixed;
    /* 设置图片相对于浏览器的位置
    距离右边180px,底部20px,宽度50px
*/
        right :180px;
        bottom:20px;
        width:80px;
    }

</style>